import {
  SEARCH_TABLE_ACCEPTED,
  SEARCH_TABLE_CONTACTED,
  SEARCH_TABLE_DECLINED,
  SEARCH_TABLE_FILTERED,
  SEARCH_TABLE_POTENTIAL,
  SEARCH_TABLE_REJECTED,
  SEARCH_TABLE_VALIDATED,
} from "core/consts";
import { SearchTable } from "core/types";

const searchMergeRequest = `
careprovider {
  address {
    address
    city
    street
    street_number
    zip_code
  }
  id
  name
}
created_at
distance
id
solutions
`;

const searchMergeCandidate = `
careprovider {
  address {
    address
    city
    street
    street_number
    zip_code
  }
  id
  inactive_reason
  name
  profile {
      accepted_age
      accepted_weight
      capacity {
      available_date
    }
  }
  status
}
distance
id
solutions`;

const contactedSectionFragment = `
contacted_requests {
requests {
  ${searchMergeRequest}
  chip_status
  patient_preferred
  patient_preferred_reason
  patient_data_shared
  recommendation_id
}
total
}`;

const filteredSectionFragment = `
filtered_candidates {
  filtered_candidates {
  ${searchMergeCandidate}
  send_fax
  recommendation_id
  solution_filter_reasons 
}
total
}`;

const rejectedSectionFragment = `
rejected_requests {
requests {
  ${searchMergeRequest}
  chip_status
  patient_preferred
  patient_preferred_reason
  validation {
    rejected_at
  }
}
total
}`;

const declinedSectionFragment = `
declined_requests {
requests {
  ${searchMergeRequest}
  chip_status
  patient_preferred
  patient_preferred_reason
  response {
    capacity_date
    declined_at
    declined_reason
    message
    no_capacity_expiration
    no_capacity_reasons
  }
}
total
}`;

const acceptedSectionFragment = `
accepted_requests {
requests {
  ${searchMergeRequest}
  chip_status
  potential_start_date
  potential_start_time
  patient_preferred
  patient_preferred_reason
  patient_data_shared
}
total
}`;

const potentialSectionFragment = `
potential_candidates {
requests {
  ${searchMergeCandidate}
}
total
}`;

// need to have empty fragment for request here to allow a null response for request
const validatedSectionFragment = `
validated_request {
request 
}`;

const contextFragment = `
context {
  search_merge_status
  search_merge_banners
}
`;

function searchMergePayload(
  props: {
    [index in SearchTable]?: boolean;
  } & { context?: boolean },
) {
  return `
${props[SEARCH_TABLE_CONTACTED] ? contactedSectionFragment : ""}
${props[SEARCH_TABLE_FILTERED] ? filteredSectionFragment : ""}
${props[SEARCH_TABLE_REJECTED] ? rejectedSectionFragment : ""}
${props[SEARCH_TABLE_DECLINED] ? declinedSectionFragment : ""}
${props[SEARCH_TABLE_ACCEPTED] ? acceptedSectionFragment : ""}
${props[SEARCH_TABLE_POTENTIAL] ? potentialSectionFragment : ""}
${props[SEARCH_TABLE_VALIDATED] ? validatedSectionFragment : ""}
${props?.context ? contextFragment : ""}
  `;
}

export default { searchMergePayload };
