import { useEnvContext } from "context/EnvContext";
import { REDIRECT_PARAM } from "core/consts";
import { isAdminLogged } from "core/model/accounts";
import { getQueryVariable } from "core/model/utils/urls";
import { useManualSetLegalDocuments } from "dsl/atoms/LegalDocuments";
import MagicLogin from "dsl/atoms/MagicLogin";
import LoginPage from "dsl/organisms/LoginPage";
import { acpAuthPath, defaultRedirectPath } from "entries/acp/Router";
import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

export default function AcpAuthPage() {
  const { app } = useEnvContext();
  const logged = useSelector(isAdminLogged);
  const location = useLocation();
  const redirectTo = getQueryVariable(location.search, REDIRECT_PARAM);

  useManualSetLegalDocuments();

  return logged === "ok" ? (
    <Navigate to={redirectTo ?? defaultRedirectPath} replace />
  ) : (
    <>
      <MagicLogin
        username={getQueryVariable(location.search, "username")}
        password={getQueryVariable(location.search, "password")}
      />
      <LoginPage app={app} />
    </>
  );
}

export function AcpAuthenticate({ children }: { children: ReactNode | null }) {
  const logged = useSelector(isAdminLogged);
  const { pathname, search } = useLocation();
  const redirectTo = encodeURIComponent(`${pathname}${search}`);

  return logged === "ok" ? (
    <>{children}</>
  ) : (
    <Navigate
      to={{ pathname: acpAuthPath, search: `${REDIRECT_PARAM}=${redirectTo}` }}
      replace
    />
  );
}
