import { NavigateFunction } from "react-router-dom";

const dashboardRoute = "/acp/dashboard";

export const getAcpNavigationHandlers = ({
  navigate,
  search,
}: {
  navigate: NavigateFunction;
  search: string;
}) => ({
  goToDashboard: () =>
    navigate({
      pathname: `${dashboardRoute}/`,
      search,
    }),
  goToInternalAccounts: () =>
    navigate({
      pathname: `${dashboardRoute}/staff`,
      search,
    }),
  goToSearch: () =>
    navigate({
      pathname: `${dashboardRoute}/search`,
      search,
    }),
  goToSealdDebug: (page?: string) =>
    navigate({
      pathname: page
        ? `${dashboardRoute}/sealddebug/${page}`
        : `${dashboardRoute}/sealddebug/account`,
    }),
  goToInsurance: (id: number | null) =>
    navigate({
      pathname: id
        ? `${dashboardRoute}/insurance/${id}`
        : `${dashboardRoute}/insurance`,
      search,
    }),
  goToAccount: (id: number) => {
    navigate({
      pathname: `${dashboardRoute}/account/${id}`,
      search,
    });
    window.scrollTo(0, 0);
  },
  goToAccountSlug: (id: number, accountSlug: string) =>
    navigate({
      pathname: `${dashboardRoute}/account/${id}/${accountSlug}`,
      search,
    }),
  goToCareseeker: (id: number) =>
    navigate({
      pathname: `${dashboardRoute}/careseeker/${id}`,
      search,
    }),
  goToCompany: (id: number) =>
    navigate({
      pathname: `${dashboardRoute}/company/${id}`,
      search,
    }),
  goToCareseekerSlug: (id: number, careseekerSlug: string) =>
    navigate({
      pathname: `${dashboardRoute}/careseeker/${id}/${careseekerSlug}`,
      search,
    }),
  goToCareproviderPage: (id: number, page: string) =>
    navigate({
      pathname: `${dashboardRoute}/careprovider/${id}/${page}`,
      search,
    }),
  goToTranslations: () =>
    navigate({
      pathname: `${dashboardRoute}/translations`,
      search,
    }),
  goToEmailTranslations: () =>
    navigate({
      pathname: `${dashboardRoute}/emailtranslations`,
      search,
    }),
  goToSendTestEmail: () =>
    navigate({
      pathname: `${dashboardRoute}/testemail`,
      search,
    }),
  goToCreateCareprovider: () =>
    navigate({
      pathname: `${dashboardRoute}/create/careprovider`,
      search,
    }),
  goToCreateCareseeker: () =>
    navigate({
      pathname: `${dashboardRoute}/create/careseeker`,
      search,
    }),
  goToConsultant: (id: number) =>
    navigate({
      pathname: `${dashboardRoute}/consultant/${id}`,
      search,
    }),
  goToCreateConsultant: () =>
    navigate({
      pathname: `${dashboardRoute}/create/consultant`,
      search,
    }),
});
