import { DialogActions, Paper, useTheme } from "@mui/material";
import {
  CareproviderAccountAssociationMutation,
  ForgotPasswordMutation,
} from "apollo/mutations";
import {
  LoginFnCancelable,
  LoginMutation,
  getLoginArguments,
  getTentativeLoginAccountId,
} from "apollo/mutations/login";
import { useEnvContext } from "context/EnvContext";
import {
  APP_CAREPROVIDER,
  APP_CLINIC,
  DOCUMENT_PRIVACY_POLICY,
  QUERY_PROGRESS_FAILED,
  QUERY_PROGRESS_NOT_STARTED,
  QUERY_PROGRESS_PENDING,
  QUERY_PROGRESS_SUCCEED,
  RESPONSIVE_BREAKPOINT_MOBILE,
  TRACK_EVENTS,
} from "core/consts";
import { useSafeState } from "core/hooks";
import auth from "core/model/api/endpoints/auth";
import { getConfig } from "core/model/config";
import { LOCAL_STORAGE_KEYS } from "core/model/localStorage";
import { getErrorMessage } from "core/model/utils/errors";
import {
  activateEnvSwitch,
  isDemo,
  isPreprod,
} from "core/model/utils/featureFlags";
import {
  getQueryVariable,
  getTopLevelDomain,
  getUrlEnvSuffix,
} from "core/model/utils/urls";
import { AppType, Env, Notify, QueryProgress } from "core/types";
import { addMinutes, getUnixTime, millisecondsToMinutes } from "date-fns";
import { ArrowLeftIcon, EmailIcon, LockIcon } from "ds/icons";
import { FlagFab } from "ds_legacy/components/FlagFab/Fab";
import { DeFlagIcon, FrFlagIcon } from "ds_legacy/components/FlagIcon";
import { InfoBanner } from "ds_legacy/components/InfoBanner";
import LinkV2 from "ds_legacy/components/LinkV2";
import RSButton from "ds_legacy/components/RSButton";
import { RecareLogoOnly } from "ds_legacy/components/RecareLogo";
import { SpinnerPage } from "ds_legacy/components/Spinner";
import { TextInputField } from "ds_legacy/components/TextInputField";
import {
  BORDER_COLOR,
  ICON_DARK,
  ICON_GREY,
  WHITE,
} from "ds_legacy/materials/colors";
import { HorizontalLayout, VerticalLayout } from "ds_legacy/materials/layouts";
import {
  border,
  dp,
  margin,
  padding,
  sizing,
} from "ds_legacy/materials/metrics";
import { SHADOW_DROP_SMALL } from "ds_legacy/materials/shadows";
import {
  Body,
  FONT_SIZE_12,
  FONT_SIZE_16,
  LINE_HEIGHT_16,
  Link,
  Subheading,
} from "ds_legacy/materials/typography";
import { EnvSelect } from "dsl/atoms/EnvSelect";
import {
  useLegalDocuments,
  useManualSetLegalDocuments,
} from "dsl/atoms/LegalDocuments";
import { useOnboarding } from "dsl/ecosystems/CareproviderOnboarding";
import { ButtonWrapper } from "dsl/ecosystems/CareproviderSettings/shared";
import { usePreventUnload } from "dsl/hooks";
import { useNotification } from "dsl/organisms/NotificationProvider";
import { usePanda } from "pandatracker-client";
import React, {
  CSSProperties,
  Suspense,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { validateEmail } from "react-forms-state";
import { useParams } from "react-router-dom";
import { useTracking } from "react-tracking";
import styled from "styled-components";
import { useTranslations } from "translations";
import { TranslationComposition } from "translations/helpers";
import Translations from "translations/types";
import LoginSuggestions from "./LoginSuggestions";

export const Overlay = styled.div`
  display: flex;
  background: radial-gradient(#40404b, #111118) rgba(34, 34, 40, 0.94);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
`;

export const LockCenter = styled.div`
  display: flex;
  align-items: center;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  height: ${sizing(9)};
  padding: ${padding(0, 3)};
  border-top-right-radius: ${dp(5)};
  border-top-left-radius: ${dp(5)};
  background: ${(props) => props.theme.palette.primary.main};
`;

export const HeaderWithIcon = styled.div`
  display: flex;
  align-items: center;
  height: ${sizing(9)};
  padding: ${padding(0, 3, 0, 1)};
  border-top-right-radius: ${dp(5)};
  border-top-left-radius: ${dp(5)};
  background: ${(props) => props.theme.palette.primary.main};
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  width: ${sizing(50)};
  height: ${sizing(4)};
  border-top: ${border({ color: BORDER_COLOR })};
  justify-content: center;
  font-size: ${FONT_SIZE_12};
`;

export const PasswordResetPageLink = styled(Subheading)`
  font-size: ${FONT_SIZE_12};
  margin: ${margin(-0.5, 2)};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${sizing(50)};
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${padding(2, 4)};
`;

export const FooterWrapper = styled.div`
  display: flex;
  padding: ${padding(2, 0, 0, 0)};
  flex-grow: 1;
  align-items: flex-end;
  justify-content: center;
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: ${margin(2, 3)};
`;

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const Widget = styled.div<{ height?: string; minHeight?: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${sizing(50)};
  margin: 0 auto;
  border-radius: ${dp(5)};
  height: ${(props) => props.height || dp(500)};
  max-height: 100vh;
  background: white;
  box-shadow: 0 0 40px 4px #111118;
  @media screen and (max-width: ${dp(RESPONSIVE_BREAKPOINT_MOBILE)}) {
    & {
      height: 100vh;
      width: 100vw;
    }
  }
  ${({ minHeight }) => minHeight && `min-height: ${minHeight};`}
`;

function useAutofilled(refs: Array<React.MutableRefObject<ToType>>) {
  const [isAutofilled, setIsAutofilled] = useState(false);
  /* eslint-disable consistent-return */
  useLayoutEffect(() => {
    if (!isAutofilled && refs.length > 0 && refs[0].current != null) {
      const timeout = setTimeout(() => {
        let accumulator = true;
        refs.forEach((r) => {
          try {
            if (r.current)
              accumulator =
                accumulator && r.current.matches(":-webkit-autofill");
          } catch (error) {
            // no ':-webkit-autofill' selector
            accumulator = false;
          }
        });
        setIsAutofilled(accumulator);
      }, 200);
      return () => clearTimeout(timeout);
    }
  }, []);
  return isAutofilled;
}

const getTitleAndUrl = (
  app: number,
  env: string,
  translations: Translations,
) => {
  const { domain } = getConfig(env as Env);

  switch (app) {
    case APP_CLINIC:
      return {
        title: isDemo
          ? translations.login.clinic.loginTitleDemo.toUpperCase()
          : isPreprod
          ? translations.login.clinic.loginTitlePreprod.toUpperCase()
          : translations.login.clinic.loginTitle.toUpperCase(),
        redirectTitle: translations.login.clinic.changeToReceiver,
        redirectUrl: `https://partner${getUrlEnvSuffix(
          env,
        )}.${domain}/careprovider/auth`,
        showEnvironmentWarning: isDemo || isPreprod,
      };

    case APP_CAREPROVIDER:
      return {
        title: translations.login.care.loginTitle.toUpperCase(),
        redirectTitle: translations.login.care.changeToSender,
        redirectUrl: `https://app${getUrlEnvSuffix(env)}.${domain}/app/auth`,
        showEnvironmentWarning: false,
      };

    default:
      return {
        title: translations.login.loginTitle.toUpperCase(),
        redirectTitle: "",
        redirectUrl: "",
        showEnvironmentWarning: false,
      };
  }
};

const getDomain = ({ flag }: { flag: string }) => {
  switch (flag) {
    case "fr": {
      return ".fr";
    }
    default:
      return ".com";
  }
};

const SWITCHER_FLAGS: {
  [key: string]: React.ComponentType<{ active?: boolean }>;
} = { de: DeFlagIcon, fr: FrFlagIcon };

const getCountrySwitcherUrl = ({
  currentApp,
  flag,
  urlSuffix,
}: {
  currentApp: string;
  flag: string;
  urlSuffix: string;
}) =>
  `https://${currentApp}${urlSuffix}.recaresolutions${getDomain({
    flag,
  })}`;

const CountrySwitcher = ({ app, env }: { app: number; env: string }) => {
  const currentApp = app === APP_CAREPROVIDER ? "partner" : "app";
  const urlSuffix = getUrlEnvSuffix(env);
  const initialFlagState = getTopLevelDomain().toLowerCase();
  const containerStyle: CSSProperties = {
    position: "fixed",
    bottom: sizing(2),
    right: sizing(3),
    zIndex: 100,
  };
  return (
    <FlagFab
      flagState={initialFlagState}
      flags={SWITCHER_FLAGS}
      containerStyleOverride={containerStyle}
      onFlagChange={(flag: string) => {
        const URL = getCountrySwitcherUrl({ currentApp, urlSuffix, flag });
        window.open(URL);
      }}
    />
  );
};

function LoginProgressPending({
  id,
  title,
  translations,
}: {
  id: string;
  title: string;
  translations: Translations;
}) {
  usePanda("LoginPageLoading");

  return (
    <React.Fragment>
      <Header>
        <HeaderWrapper>
          <Subheading light>{title}</Subheading>
          <RecareLogoOnly />
        </HeaderWrapper>
      </Header>
      <VerticalLayout margin={margin(9, 0)} height={sizing(12)}>
        <SpinnerPage id={id} />
      </VerticalLayout>
      <VerticalLayout margin={margin(0, 4)}>
        <Subheading bold>
          {translations.login.loading.settingEnvironment}
        </Subheading>
        <Body>{translations.login.loading.dontLeavePage}</Body>
      </VerticalLayout>
    </React.Fragment>
  );
}

export function LoginModule({
  email,
  error,
  isOnboardingFlow,
  onLogin,
  onLoginQueryProgress,
  onPasswordResetPage,
  password,
  passwordRequired,
  setEmail,
  setPassword,
  status,
  translations,
}: {
  email: string;
  error: string | null;
  isOnboardingFlow?: boolean;
  onLogin: () => void;
  onLoginQueryProgress: QueryProgress;
  onPasswordResetPage: () => void;
  password: string;
  passwordRequired: boolean;
  setEmail: (email: string) => void;
  setPassword: (pass: string) => void;
  status: string;
  translations: Translations;
}) {
  const emailRef = useRef();
  const passwordRef = useRef();
  const isAutofilled = useAutofilled([emailRef, passwordRef]);
  const canSubmit =
    onLoginQueryProgress !== QUERY_PROGRESS_PENDING &&
    (isAutofilled ||
      (passwordRequired && password && email) ||
      (!passwordRequired && email));

  const { app } = useEnvContext();
  const { env } = useEnvContext();

  const { redirectTitle, redirectUrl, showEnvironmentWarning, title } =
    getTitleAndUrl(app, env, translations);

  const { token } = useParams();

  useEffect(() => {
    if (token) onLogin();
  }, [token]);

  if (onLoginQueryProgress === QUERY_PROGRESS_PENDING)
    return (
      <LoginProgressPending
        id="login-page"
        title={title}
        translations={translations}
      />
    );

  if (isOnboardingFlow)
    return (
      <VerticalLayout
        as="form"
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          onLogin();
        }}
      >
        <Header>
          <HeaderWrapper>
            <Subheading bold light>
              {title}
            </Subheading>
            <RecareLogoOnly />
          </HeaderWrapper>
        </Header>
        <VerticalLayout stretched style={{ marginTop: sizing(3) }}>
          <TextInputField
            autoFocus
            onChange={setEmail}
            autoComplete="on"
            validateOverride=" "
            required
            label={translations.login.emailAddress}
            validation={onLoginQueryProgress !== QUERY_PROGRESS_FAILED}
            textInputType="email"
            elementName="email"
            value={email}
            inputRef={emailRef}
            width="90%"
          />
          {passwordRequired ? (
            <VerticalLayout>
              <HorizontalLayout stretched>
                <TextInputField
                  onChange={setPassword}
                  autoComplete="current-password"
                  label={translations.login.password}
                  validateOverride=" "
                  required
                  validation={onLoginQueryProgress !== QUERY_PROGRESS_FAILED}
                  textInputType="password"
                  elementName="password"
                  value={password}
                  inputRef={passwordRef}
                  width="90%"
                />
              </HorizontalLayout>
              <PasswordResetPageLink primary bold onClick={onPasswordResetPage}>
                {translations.login.forgotYourPassword}
              </PasswordResetPageLink>
            </VerticalLayout>
          ) : (
            <HorizontalLayout stretched>
              <Body margin={margin(2, 2, 4.75, 2)}>
                <LockIcon
                  style={{
                    color: ICON_DARK,
                    fontSize: FONT_SIZE_16,
                    margin: margin(0, 1, -0.5, 0),
                  }}
                  size={FONT_SIZE_16}
                />
                Single sign-on enabled
              </Body>
            </HorizontalLayout>
          )}
        </VerticalLayout>
        {status === "login" && error != null && <Body error>{error}</Body>}
        <DialogActions
          sx={{ justifyContent: "center", margin: margin(2, 0, 1, 0) }}
        >
          <RSButton
            id="login"
            className="login"
            variant="contained"
            type="submit"
            color="primary"
            loading={onLoginQueryProgress}
            disabled={!canSubmit}
            onClick={() => {}}
            style={{ width: dp(220), margin: 0 }}
          >
            {translations.login.login}
          </RSButton>
        </DialogActions>
      </VerticalLayout>
    );

  return (
    <React.Fragment>
      <Header>
        <HeaderWrapper>
          <Subheading bold light>
            {title}
          </Subheading>
          <RecareLogoOnly />
        </HeaderWrapper>
      </Header>

      <StyledForm
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          onLogin();
        }}
      >
        <LoginSuggestions
          setEmail={setEmail}
          setPassword={setPassword}
          onLogin={onLogin}
          currentEnv={env}
        />
        <VerticalLayout stretched style={{ marginTop: sizing(3) }}>
          {showEnvironmentWarning && (
            <InfoBanner
              message={getTranslationCompositionLink(
                translations.login.clinic.bannerDemo,
                "https://app.recaresolutions.com",
              )}
              noIcon
              severity="warning"
              wrapperStyle={{ width: "100%", marginBottom: sizing(1) }}
            />
          )}
          <TextInputField
            autoFocus
            onChange={setEmail}
            autoComplete="on"
            validateOverride=" "
            required
            label={translations.login.emailAddress}
            validation={onLoginQueryProgress !== QUERY_PROGRESS_FAILED}
            textInputType="email"
            elementName="email"
            value={email}
            inputRef={emailRef}
            width="90%"
          />
          {passwordRequired ? (
            <VerticalLayout>
              <HorizontalLayout stretched>
                <TextInputField
                  onChange={setPassword}
                  autoComplete="current-password"
                  label={translations.login.password}
                  validateOverride=" "
                  required
                  validation={onLoginQueryProgress !== QUERY_PROGRESS_FAILED}
                  textInputType="password"
                  elementName="password"
                  value={password}
                  inputRef={passwordRef}
                  width="90%"
                />
              </HorizontalLayout>
              <PasswordResetPageLink primary bold onClick={onPasswordResetPage}>
                {translations.login.forgotYourPassword}
              </PasswordResetPageLink>
            </VerticalLayout>
          ) : (
            <HorizontalLayout stretched>
              <Body
                display="flex"
                flex="row nowrap"
                margin={margin(2, 2, 6.25, 2)}
              >
                <LockIcon
                  style={{
                    color: ICON_DARK,
                    fontSize: FONT_SIZE_16,
                    margin: margin(0, 1, -0.5, 0),
                  }}
                  size={FONT_SIZE_16}
                />
                {translations.login.ssoEnabled}
              </Body>
            </HorizontalLayout>
          )}
        </VerticalLayout>
        {status === "login" && error != null && <Body error>{error}</Body>}
        <>
          <DialogActions
            sx={{ justifyContent: "center", margin: margin(1, 0) }}
          >
            <RSButton
              className="login"
              color="primary"
              disabled={!canSubmit}
              id="login"
              loading={onLoginQueryProgress}
              onClick={() => {}}
              style={{ width: dp(220), margin: 0 }}
              type="submit"
              variant="contained"
            >
              {translations.login.login}
            </RSButton>
          </DialogActions>
          {redirectTitle && (
            <BottomWrapper>
              <Body
                lineHeight={LINE_HEIGHT_16}
                fontSize={FONT_SIZE_12}
                secondary
              >
                {translations.login.haveAnAccountYet}&nbsp;
                <Link
                  href={translations.login.contactUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  secondary
                >
                  {translations.login.contactUs}
                </Link>
              </Body>

              <Body
                lineHeight={LINE_HEIGHT_16}
                fontSize={FONT_SIZE_12}
                secondary
              >
                {redirectTitle}&nbsp;
                <Link
                  aria-label={translations.login.clinic.clickHere}
                  href={redirectUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  secondary
                >
                  {translations.login.clinic.clickHere}
                </Link>
              </Body>
            </BottomWrapper>
          )}
        </>
      </StyledForm>
      <CountrySwitcher app={app} env={env} />
    </React.Fragment>
  );
}

export function ChallengeModule({
  challenge,
  error,
  onLogin,
  onLoginPage,
  onLoginQueryProgress,
  setChallenge,
  status,
  translations,
}: {
  challenge: string | undefined;
  error: string | null;
  onLogin: () => void;
  onLoginPage: () => void;
  onLoginQueryProgress: QueryProgress;
  setChallenge: (challenge: string | undefined) => void;
  status: string;
  translations: Translations;
}) {
  const canSubmit =
    onLoginQueryProgress !== QUERY_PROGRESS_PENDING && challenge;

  const { app } = useEnvContext();
  const { env } = useEnvContext();

  const title = translations.login.challengeScreen.title;
  const datetimestring =
    window.sessionStorage.getItem(LOCAL_STORAGE_KEYS.CHALLENGE_DATETIME) ?? "";

  if (onLoginQueryProgress === QUERY_PROGRESS_PENDING)
    return (
      <LoginProgressPending
        id="challenge-page"
        title={title}
        translations={translations}
      />
    );

  return (
    <React.Fragment>
      <HeaderWithIcon>
        <ArrowLeftIcon
          style={{ color: WHITE, cursor: "pointer" }}
          onClick={onLoginPage}
        />
        <HeaderWrapper>
          <Subheading bold light>
            {title}
          </Subheading>
          <RecareLogoOnly />
        </HeaderWrapper>
      </HeaderWithIcon>

      <StyledForm
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          onLogin();
        }}
      >
        <VerticalLayout stretched style={{ marginTop: sizing(3) }}>
          <VerticalLayout aligned margin={margin(4, 0)}>
            <EmailIcon
              style={{ margin: margin(0, 0, 2, 0), color: ICON_GREY }}
              size={FONT_SIZE_16}
            />
            <Body>{translations.login.challengeScreen.infoText} </Body>
          </VerticalLayout>
          <TextInputField
            autoFocus
            onChange={setChallenge}
            validateOverride=" "
            required
            label={translations.login.challengeScreen.placeholder}
            validation={onLoginQueryProgress !== QUERY_PROGRESS_FAILED}
            textInputType="challenge"
            elementName="challenge"
            value={challenge ?? ""}
            width="90%"
          />
          {status === "login" && error != null && <Body error>{error}</Body>}
          {!error && (
            <Body>
              {translations.login.challengeScreen.infoTextTimestamp({
                datetimestring,
              })}
            </Body>
          )}
        </VerticalLayout>
        <DialogActions
          sx={{ justifyContent: "center", margin: margin(2, 0, 1, 0) }}
        >
          <RSButton
            className="login"
            color="primary"
            disabled={!canSubmit}
            id="login"
            loading={onLoginQueryProgress}
            onClick={() => {}}
            style={{ width: dp(220), margin: 0 }}
            type="submit"
            variant="contained"
          >
            {translations.login.login}
          </RSButton>
        </DialogActions>
      </StyledForm>
      <CountrySwitcher app={app} env={env} />
    </React.Fragment>
  );
}

export function PasswordReset({
  app,
  careproviderToken,
  email,
  onLoginPage,
  onSuccess,
  setEmail,
}: {
  app: AppType;
  careproviderToken?: string;
  email: string;
  onLoginPage: () => void;
  onSuccess: () => void;
  setEmail: (email: string) => void;
}) {
  const translations = useTranslations();
  return (
    <ForgotPasswordMutation
      onCompleted={() => {
        onSuccess();
        onLoginPage();
      }}
    >
      {(
        onForgotPassword: (args: ToType) => Promise<ToType>,
        onForgotPasswordQueryProgress: QueryProgress,
      ) => (
        <React.Fragment>
          <HeaderWithIcon>
            <ArrowLeftIcon
              style={{ cursor: "pointer", color: WHITE }}
              onClick={onLoginPage}
              size={FONT_SIZE_16}
            />
            <HeaderWrapper>
              <Subheading margin={margin(1, 1)} light>
                {translations.login.passwordForgottenTitle}
              </Subheading>
              <RecareLogoOnly />
            </HeaderWrapper>
          </HeaderWithIcon>
          <Form>
            <Body>{translations.login.forgotPasswordDetails}</Body>
            <div
              style={{
                display: "flex",
                margin: margin(2, 0),
              }}
            >
              <TextInputField
                onChange={setEmail}
                label={translations.login.emailAddress}
                textInputType="email"
                elementName="email"
                value={email}
                width={dp(300)}
              />
            </div>
            {onForgotPasswordQueryProgress === QUERY_PROGRESS_FAILED && (
              <Body error>{translations.login.passwordResetError}</Body>
            )}
            <div
              style={{
                margin: margin(1, 0),
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: dp(220),
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <ButtonWrapper>
                  <RSButton
                    color="primary"
                    disabled={
                      onForgotPasswordQueryProgress === QUERY_PROGRESS_PENDING
                    }
                    id="send_reset_email"
                    loading={onForgotPasswordQueryProgress}
                    onClick={() =>
                      onForgotPassword({
                        email,
                        app,
                        onboarding_token: careproviderToken,
                      })
                    }
                    style={{ width: dp(220), margin: 0 }}
                    variant="contained"
                  >
                    {translations.login.sendResetEmail}
                  </RSButton>
                </ButtonWrapper>
              </div>
            </div>
          </Form>
        </React.Fragment>
      )}
    </ForgotPasswordMutation>
  );
}

function EnvSwitcher() {
  if (!activateEnvSwitch) return null;

  return (
    <Suspense fallback={<div />}>
      <div
        style={{
          left: 0,
          right: 0,
          position: "fixed",
          top: dp(15),
          zIndex: 10000,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Paper
          sx={{
            backgroundColor: "white",
            padding: padding(1, 2),
            width: dp(200),
          }}
        >
          <EnvSelect />
        </Paper>
      </div>
    </Suspense>
  );
}

export const OnboardingWidget = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${sizing(50)};
  margin: 0 auto;
  border-radius: ${dp(5)};
  height: ${dp(500)};
  max-height: 100vh;
  background: white;
  box-shadow: ${SHADOW_DROP_SMALL};
  @media screen and (max-width: ${dp(RESPONSIVE_BREAKPOINT_MOBILE)}) {
    & {
      height: 100vh;
      width: 100vw;
    }
  }
`;

function LoginPageView({
  app,
  careproviderToken,
  challenge,
  email,
  error,
  isOnboardingFlow,
  module,
  notify,
  onLogin,
  onLoginQueryProgress,
  password,
  passwordRequired,
  setChallenge,
  setEmail,
  setModule,
  setPassword,
  status,
  translations,
}: {
  app: AppType;
  careproviderToken?: string;
  challenge: string | undefined;
  email: string;
  error: string | null;
  isOnboardingFlow?: boolean;
  module: string;
  notify: Notify;
  onLogin: () => void;
  onLoginQueryProgress: QueryProgress;
  password: string;
  passwordRequired: boolean;
  setChallenge: (challenge: string | undefined) => void;
  setEmail: (email: string) => void;
  setModule: (module: string) => void;
  setPassword: (pass: string) => void;
  status: string;
  translations: Translations;
}) {
  const theme = useTheme();

  // Setting the legal documents to default on the login screen, otherwise
  // it tries to fetch with the id (logout/onboarding), although logged out
  useManualSetLegalDocuments();
  const { getDocumentUrl } = useLegalDocuments();

  if (isOnboardingFlow)
    return (
      <OnboardingWidget>
        {module === "login" && (
          <LoginModule
            translations={translations}
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            onLogin={onLogin}
            status={status}
            onLoginQueryProgress={onLoginQueryProgress}
            onPasswordResetPage={() => setModule("passwordReset")}
            error={error}
            passwordRequired={passwordRequired}
            isOnboardingFlow={isOnboardingFlow}
          />
        )}
        {module === "passwordReset" && (
          <PasswordReset
            email={email}
            setEmail={setEmail}
            onSuccess={() =>
              notify({
                message: translations.login.passwordForgottenSuccess,
                type: "success",
              })
            }
            onLoginPage={() => setModule("login")}
            app={app}
            careproviderToken={careproviderToken}
          />
        )}
        {module === "challenge" && (
          <ChallengeModule
            translations={translations}
            challenge={challenge}
            setChallenge={setChallenge}
            onLogin={onLogin}
            status={status}
            onLoginQueryProgress={onLoginQueryProgress}
            onLoginPage={() => setModule("login")}
            error={error}
          />
        )}

        <FooterWrapper>
          <Footer>
            <LinkV2
              href={getDocumentUrl(DOCUMENT_PRIVACY_POLICY)}
              color={theme.palette.common.black}
            >
              {translations.login.privacyPolicy}
            </LinkV2>
          </Footer>
        </FooterWrapper>
      </OnboardingWidget>
    );

  return (
    <div>
      <Overlay>
        <LockCenter>
          <Widget height="initial" minHeight={dp(500)}>
            {module === "login" && (
              <LoginModule
                translations={translations}
                email={email}
                setEmail={setEmail}
                password={password}
                setPassword={setPassword}
                onLogin={onLogin}
                status={status}
                onLoginQueryProgress={onLoginQueryProgress}
                onPasswordResetPage={() => setModule("passwordReset")}
                error={error}
                passwordRequired={passwordRequired}
              />
            )}
            {module === "passwordReset" && (
              <PasswordReset
                email={email}
                setEmail={setEmail}
                onSuccess={() =>
                  notify({
                    message: translations.login.passwordForgottenSuccess,
                    type: "success",
                  })
                }
                onLoginPage={() => setModule("login")}
                app={app}
              />
            )}
            {module === "challenge" && (
              <ChallengeModule
                translations={translations}
                challenge={challenge}
                setChallenge={setChallenge}
                onLogin={onLogin}
                status={status}
                onLoginQueryProgress={onLoginQueryProgress}
                onLoginPage={() => setModule("login")}
                error={error}
              />
            )}

            <FooterWrapper>
              <Footer>
                <LinkV2
                  href={getDocumentUrl(DOCUMENT_PRIVACY_POLICY)}
                  color={theme.palette.common.black}
                >
                  {translations.login.privacyPolicy}
                </LinkV2>
              </Footer>
            </FooterWrapper>
          </Widget>
        </LockCenter>
        <EnvSwitcher />
      </Overlay>
    </div>
  );
}

export type ErrorType = {
  isCanceled: boolean;
  message?: string;
  needsChallenge?: boolean;
};

type LoginError = ErrorType & {
  expires_at: number;
  remaining_time?: number;
  status: number;
};
export function getLoginError(err: ErrorType): LoginError {
  const error: LoginError = {
    status: -1,
    expires_at: getUnixTime(addMinutes(new Date(), 5)),
    ...err,
  };

  try {
    if (err.message != null && typeof err.message === "string") {
      const json = JSON.parse(err.message);

      error.status = json.status;
      error.expires_at = json.body.expires_at;
      if (json.body.remaining_time) {
        error.remaining_time = json.body.remaining_time;
      }
    }
  } catch (e) {
    console.warn("Could not parse error message", e);
  }

  return error;
}

type Associate = (data: { email: string }) => Promise<any>;

export default function LoginPage({
  app,
  careproviderToken,
  careproviderToLog,
  isOnboardingFlow,
}: {
  app: AppType;
  careproviderToLog?: number;
  careproviderToken?: string;
  isOnboardingFlow?: boolean;
}) {
  const { next } = useOnboarding();
  const translations = useTranslations();
  const { trackEvent } = useTracking();
  const notify = useNotification();
  const [error, setError] = useState<string | null>(null);
  const [module, setModule] = useState("login");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [oauthToken, setOAuthToken] = useState<string | undefined>();
  const [challenge, setChallenge] = useState<string | undefined>();
  const [status, setStatus] = useState("");
  const [submitData, setDidSubmitData] = useState<{
    associate?: Associate;
    didSubmit: boolean;
    login: LoginFnCancelable | undefined;
  }>({
    associate: undefined,
    didSubmit: false,
    login: undefined,
  });
  const [loginProgress, setLoginProgress] = useSafeState<QueryProgress>(
    QUERY_PROGRESS_NOT_STARTED,
  );
  const { token } = useParams();

  const skip = password == "";
  usePreventUnload(loginProgress, skip);

  useEffect(() => setStatus("login"), [loginProgress]);

  useEffect(() => {
    setLoginProgress(QUERY_PROGRESS_NOT_STARTED);
    setChallenge(undefined);
    setError(null);
  }, [module]);

  useEffect(() => {
    const verifiedDevice = getQueryVariable(
      window.location.search,
      "verified_device",
    );
    if (verifiedDevice === "false") {
      setError(translations.login.mfa.invalidToken);
    } else if (verifiedDevice === "true") {
      notify({ message: translations.login.mfa.deviceVerified });
    }

    const logoutReason = sessionStorage.getItem("logout_reason");
    if (logoutReason === "reset_identity") {
      notify({ message: translations.general.loginPostIdentityReset });
    }
    sessionStorage.removeItem("logout_reason");
  }, []);

  useEffect(() => {
    if (validateEmail({ email, required: true }) === true || oauthToken) {
      auth
        .oauthCheck({ email })
        .then((res) => {
          if (res?.url) setOAuthToken(res.url);
          else throw new Error();
        })
        .catch((_) => {
          setOAuthToken(undefined);
        });
    }
  }, [email]); // only sync on email changes

  useLayoutEffect(() => {
    async function processLogin() {
      if (submitData.login) {
        setLoginProgress(QUERY_PROGRESS_PENDING);
        if (oauthToken) {
          window.location.href = oauthToken;
          return;
        }
        const loginArgs = await getLoginArguments({
          username: email,
          password,
          challenge,
          careproviderToLog,
          token,
        });
        const loginPromise = submitData.login(loginArgs);

        loginPromise.promise
          .then(() => setLoginProgress(QUERY_PROGRESS_SUCCEED))
          .then(async () => {
            if (!submitData.associate) return null;
            await submitData.associate({ email });
            next();
          })
          .catch((err: ErrorType) => {
            if (err.isCanceled) return null;
            const {
              needsChallenge,
              remaining_time = 0,
              status,
            } = getLoginError(err);
            const rawError = getErrorMessage(err);
            let reason;
            switch (status) {
              case 429:
                reason =
                  translations.login.biometricfailedAlert.falsePasswordLimitReached(
                    {
                      timer: String(millisecondsToMinutes(remaining_time) + 1),
                    },
                  );
                break;
              default:
                if (rawError.includes("connected with unauthorized IP")) {
                  reason = "Connect to 'ACP Connect' in Perimeter81";
                } else if (rawError.includes("no private key")) {
                  reason = translations.auctionResponse.accessDeniedError;
                } else if (
                  rawError === translations.login.toastInactiveProvider
                ) {
                  reason = translations.login.toastInactiveProvider;
                } else if (rawError === "cleandata in progress") {
                  reason = "cleandata in progress";
                } else if (needsChallenge) {
                  setModule("challenge");
                  return setLoginProgress(QUERY_PROGRESS_NOT_STARTED);
                } else {
                  if (challenge) {
                    const datetimestring =
                      window.sessionStorage.getItem(
                        LOCAL_STORAGE_KEYS.CHALLENGE_DATETIME,
                      ) ?? "";

                    reason =
                      translations.login.challengeScreen.verificationInvalidTimestamp(
                        { datetimestring },
                      );
                  } else if (token) {
                    reason = "Invalid token";
                  } else if (rawError.toLowerCase().includes("seald"))
                    reason = rawError;
                  else {
                    reason = translations.login.wrongEmailPassword;
                  }
                }
                break;
            }

            trackEvent({
              name: TRACK_EVENTS.LOGIN_ATTEMPT_FAILED,
              reason: reason,
              error_message: rawError,
              account_id: getTentativeLoginAccountId(),
              email,
            });

            if (reason) setError(reason);
            else throw new Error("No reason for failed login");

            return setLoginProgress(QUERY_PROGRESS_FAILED);
          });
        return () => loginPromise.cancel();
      }
    }

    if (submitData?.didSubmit) {
      processLogin();
    }
  }, [submitData, setError]);

  if (isOnboardingFlow && careproviderToLog)
    return (
      <LoginMutation cancelable>
        {(login: LoginFnCancelable) => (
          <CareproviderAccountAssociationMutation
            careproviderId={careproviderToLog}
            token={careproviderToken}
          >
            {(associate: Associate) => (
              <LoginPageView
                onLoginQueryProgress={loginProgress}
                module={module}
                setModule={setModule}
                email={email}
                setEmail={setEmail}
                password={password}
                setPassword={setPassword}
                passwordRequired={!oauthToken}
                challenge={challenge}
                setChallenge={setChallenge}
                onLogin={() =>
                  setDidSubmitData({
                    didSubmit: true,
                    login,
                    associate,
                  })
                }
                translations={translations}
                status={status}
                app={app}
                notify={notify}
                error={error}
                isOnboardingFlow={isOnboardingFlow}
                careproviderToken={careproviderToken}
              />
            )}
          </CareproviderAccountAssociationMutation>
        )}
      </LoginMutation>
    );

  return (
    <LoginMutation cancelable>
      {(login: LoginFnCancelable) => (
        <LoginPageView
          onLoginQueryProgress={loginProgress}
          module={module}
          setModule={setModule}
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          passwordRequired={!oauthToken}
          challenge={challenge}
          setChallenge={setChallenge}
          onLogin={() =>
            setDidSubmitData({
              didSubmit: true,
              login,
            })
          }
          translations={translations}
          status={status}
          app={app}
          notify={notify}
          error={error}
        />
      )}
    </LoginMutation>
  );
}

function getTranslationCompositionLink(translation: string, link: string) {
  return (
    <TranslationComposition translations={translation} withOptions>
      {([before, selectLink, after]) => (
        <span>
          {before}
          <LinkV2 href={link}>{selectLink}</LinkV2>
          {after}
        </span>
      )}
    </TranslationComposition>
  );
}
